.text-small {
  font-size: 12px;
}

.color-gray {
  color: rgba(0, 0, 0, 0.64);
}

.__react_component_tooltip.show{
  opacity: 1;
  z-index: 99999;
}

.__react_component_tooltip.place-bottom {
  margin-top: 4px;
}

.__react_component_tooltip {
  padding: 14px 21px;
}